$(()->
  #
  window.THOR = {};

  runAsync = (func, that, ...args)->
    if not _.isFunction func
      throw 'runAsync() needs a func passed to it. not some string to eval'
      return

    callback = ->
      func.apply that, args
      return

    window.setTimeout callback, 0

    return


  $(window).scroll ->
    scroll_top = $(window).scrollTop()
    page_height = $(window).height()


    # might be able to swap this to the visible api or something
    $('table img:not([src]):visible').each (i)->
      el_top = $(this).offset().top
      if el_top - scroll_top > -50 and el_top <= page_height + scroll_top
        runAsync ->
          if not $(this).attr 'src'
            $(this).attr 'src', $(this).data('image-src')
          return
        , this

      # break the loop after the end of the page
      if el_top > page_height + scroll_top
        return false

      return
    return

  minutesToHuman = (mins)->

    if mins <= 0
      return '0'

    str = []
    if mins >= 60
      str.push "#{mins // 60}h"
      mins %= 60

    if mins > 0
      str.push "#{mins}m"

    return str.join ''

  boolToHuman = (bool)->
    if bool
      return 'Yes'
    return 'No'

  THOR.loadRecipes = ->

    $recipes = $('#recipes')
    $tbody = $recipes.find('tbody')

    $tbody.html 'Loading...'

    $.ajax
      url: '/api/v1/recipes'
      success: (rows)->

        $trs = []

        _.each rows, (row)->

          $tr = $("""
            <tr>
              <td><a href="/recipes/#{row.id}">#{row.title}</a></td>
              <td>#{row.summary}</td>
              <td><img data-image-src="#{row.image}" /></td>
              <td>#{Math.round row.energy}</td>
              <td>#{Math.round row.calories}</td>
              <td>#{Math.round row.carbs}</td>
              <td>#{Math.round row.protein}</td>
              <td>#{Math.round row.dietary_fiber}</td>
              <td>#{Math.round row.fat}</td>
              <td>#{Math.round row.sat_fat}</td>
              <td>#{Math.round row.sugar}</td>
              <td>#{row.serves}</td>
              <td>#{minutesToHuman row.prep_time}</td>
              <td>#{minutesToHuman row.cook_time}</td>
              <td>#{boolToHuman row.is_snack}</td>
              <td>#{boolToHuman row.is_breakfast}</td>
              <td>#{boolToHuman row.is_lunch}</td>
              <td>#{boolToHuman row.is_dinner}</td>
            </tr>
          """)

          $trs.push $tr
          return

        $tbody.html ''
        $tbody.append $trs

        $(window).scroll()
        return
    return


  THOR.loadRecipe = (recipe_id)->

    $.ajax
      url: "/api/v1/recipes/#{recipe_id}"
      success: (row)->
        console.log row
        return

        $trs = []

        _.each rows, (row)->

          $tr = $("""
            <tr>
              <td><a href="/recipes/#{row.id}">#{row.title}</a></td>
              <td>#{row.summary}</td>
              <td><img data-image-src="#{row.image}" /></td>
              <td>#{Math.round row.energy}</td>
              <td>#{Math.round row.calories}</td>
              <td>#{Math.round row.carbs}</td>
              <td>#{Math.round row.protein}</td>
              <td>#{Math.round row.dietary_fiber}</td>
              <td>#{Math.round row.fat}</td>
              <td>#{Math.round row.sat_fat}</td>
              <td>#{Math.round row.sugar}</td>
              <td>#{row.serves}</td>
              <td>#{minutesToHuman row.prep_time}</td>
              <td>#{minutesToHuman row.cook_time}</td>
              <td>#{boolToHuman row.is_snack}</td>
              <td>#{boolToHuman row.is_breakfast}</td>
              <td>#{boolToHuman row.is_lunch}</td>
              <td>#{boolToHuman row.is_dinner}</td>
            </tr>
          """)

          $trs.push $tr
          return

        $tbody.html ''
        $tbody.append $trs

        $(window).scroll()
        return
    return

)